import AxiosService from '../../base/portal_env_config/axiosService';
import { BaseResponse } from '../../model/base/baseresponse';
import { paths } from '../../base/portal_env_config/urlconstants';

export default class LayoutService {

    // public static async updateGCMToken(req): Promise<BaseResponse> {
    //     return AxiosService.httpGet(paths.GET_BOOKED_PACKAGES,req).then((res) => {
    //             return Promise.resolve(res);
    //         }).catch(err => {
    //             if (err) {
    //                 console.log(err);
    //                 return Promise.reject(err);
    //             }
    //         });
    // }

    public static async getNotificationDetails(profileId, startDate, endDate): Promise<BaseResponse> {
        return AxiosService.httpGet(paths.GET_NOTIFICATIONS, { profileId: profileId, startDate: startDate, endDate: endDate, scrollDirection: 1, offset: 10 }).then((res) => {
            return Promise.resolve(res.data);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async subscribe(userDetails): Promise<any> {
        return AxiosService.httpPost(paths.RECORD_PACKAGE_INTEREST, userDetails).then((res) => {
            return Promise.resolve(res.data);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }
    public static async careers(userDetails): Promise<any> {
        return AxiosService.httpPost(paths.RECORD_CAREERS, userDetails).then((res) => {
            return Promise.resolve(res.data);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }
}

